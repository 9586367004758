import { api } from "@/api";
import { types, flow, SnapshotOut } from "mobx-state-tree";
const BatchFileItem = types.model("BatchFileItem", {
  id: types.number,
  filename: types.string,
  filesize: types.number,
  modify_time: types.string,
  location: types.string,
});
const BatchTaskItem = types.model("BatchTaskItem", {
  file_name: types.string,
  created_at: types.string,
  task_id: types.number,
  name: types.string,
  status: types.maybe(types.string),
});

export type IBatchFileItem = SnapshotOut<typeof BatchFileItem>;
export type IBatchTaskItem = SnapshotOut<typeof BatchTaskItem>;
export type MarkBatchTasks = {
  taskid: string;
  key: "judge_human" | "judge_llm";
  value: "bad" | "good";
};
export const Batch = types
  .model("Batch", {
    fileList: types.array(BatchFileItem),
    taskList: types.array(BatchTaskItem),
    chatList: types.array(types.frozen()),
    chatItem: types.array(types.frozen()),
    currentTaskId: types.maybeNull(types.number),
    currentTaskState: types.maybeNull(types.string),
    taskState: types.array(types.number),
    taskSummary: types.frozen(),
    totalThreads: types.number,
    currentChatId: types.string,
  })
  .actions((self) => ({
    setFileList(list: IBatchFileItem[]) {
      self.fileList.replace(list);
    },
    setTaskList(list: IBatchTaskItem[]) {
      self.taskList.replace(list);
    },
    setChatList(val: any) {
      self.chatList = val;
    },
    setChatItem(val: any) {
      self.chatItem = val;
    },
    setCurrentTask(val: any) {
      self.currentTaskId = val;
    },
    setCurrentTaskState(val: any) {
      self.currentTaskState = val;
    },
    setTaskState(val) {
      self.taskState = val;
    },
    resetSummary() {
      self.taskSummary = {};
      self.totalThreads = 0;
    },
    setCurrentChatId(id:string) {
      self.currentChatId = id;
    }
  }))
  .actions((self) => {
    const uploadFlie = flow(function* (file, assistant_id) {
      const res = yield api.uploadBatchFile(file, assistant_id);
      loadFlie(assistant_id);
      return res;
    });

    const loadFlie = flow(function* (assistant_id) {
      const files: ResolvePromiseReturnType<typeof api.getBatchFile> =
        yield api.getBatchFile(assistant_id);
      files.reverse();
      self.setFileList(files);
    });
    const deleteFile = flow(function* (file_id: number, assistant_id: string) {
      yield api.deleteBatchFile(file_id, assistant_id);
      loadFlie(assistant_id);
    });

    const downFile = flow(function* (_: any, assistant_id: string, id: number) {
      const file = yield api.downBatchFile(id, assistant_id, id);
      return file;
    });
    const createTasks = flow(function* (data: {
      file_id;
      assistant_id;
      llm_config;
      meta_config;
      process_config;
      begin_threads;
      end_threads;
      need_llm;
      task_name;
      one_by_one;
    }) {
      yield api.createBatchTasks(data);
      loadTasks(data.assistant_id);
    });
    const loadTasks = flow(function* (assistant_id) {
      const tasks: ResolvePromiseReturnType<typeof api.getBatchTasks> =
        yield api.getBatchTasks(assistant_id);
      tasks.reverse();
      self.setTaskList(tasks);
    });
    const tasksOutput = flow(function* (
      task_id: any,
      page: number,
      search: string,
      assistant_id: string
    ) {
      const tasks: ResolvePromiseReturnType<typeof api.getBatchTasksOutput> =
        yield api.getBatchTasksOutput(task_id, page, search, assistant_id);
      self.taskSummary = tasks.data?.summary;
      self.totalThreads = tasks.data?.summary?.total_thread ?? 0;
      return tasks;
    });
    const deleteTasks = flow(function* (task_id: number, assistant_id) {
      yield api.deleteBatchTasks(task_id, assistant_id);
      loadTasks(assistant_id);
    });

    // 新版本已废弃 - 批量对话标记接口
    const markBatchTasks = flow(function* (data: MarkBatchTasks) {
      const res = yield api.markBatchTasks(data);
      return res;
    });
    const getllmconfig = flow(function* () {
      const res = yield api.getllmconfig();
      return res;
    });
    const getChatItem = flow(function* (thread_id: string) {
      const res = yield api.getChatItem(thread_id);
      return res;
    });

    const downloadBatchTasks = flow(function* (
      task_id: any,
      assistant_id: string
    ) {
      const res = yield api.downloadBatchTasks(task_id, assistant_id);
      return res;
    });

    const getThumbData = flow(function* (data: {
      source: string[];
      extract: boolean | undefined;
      deal: boolean | undefined;
      reject: string[];
      assistant_id: string;
      thumb: number[];
      start_time: number | null;
      end_time: number | null;
    }) {
      const res = yield api.getThumbData(data);
      return res;
    });
    const createBatchFile = flow(function* (data, is_all, assistant_id) {
      const res = yield api.createBatchFile(data, is_all, assistant_id);
      loadFlie(assistant_id);
      return res;
    });
    const historyCleanse = flow(function* (data: {
      file_name;
      file_location;
      meta_data;
    }) {
      const res = yield api.historyCleanse(data);
      return res;
    });

    const messagesCreate = flow(function* (data: {
      assistant_id;
      his_file_location;
    }) {
      const res = yield api.messagesCreate(data);
      return res;
    });
    return {
      uploadFlie,
      loadFlie,
      deleteFile,
      downFile,
      createTasks,
      loadTasks,
      tasksOutput,
      deleteTasks,
      markBatchTasks,
      getllmconfig,
      getChatItem,
      downloadBatchTasks,
      getThumbData,
      createBatchFile,
      historyCleanse,
      messagesCreate,
    };
  });
