import ReactMarkdown from "react-markdown";
import "github-markdown-css/github-markdown.css";
// import remarkGfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
import rehypeRaw from "rehype-raw";
import rehypeRewrite from "rehype-rewrite";
import remarkUnwrapImages from "remark-unwrap-images";
import { observer } from "mobx-react-lite";

export const MarkdownView = observer(({ message }: { message: string }) => {
  return (
    <div className="markdown-body !bg-transparent !text-[#1f2328]">
      <ReactMarkdown
        components={{
          em: ({ ...props }) => <span>*{props.children}*</span>, // 禁用斜体，保持原样
        }}
        rehypePlugins={[rehypeRaw, [rehypeRewrite]]}
        remarkPlugins={[remarkBreaks, remarkUnwrapImages]}
      >
        {message}
      </ReactMarkdown>
    </div>
  );
});
