import { useMemo, ReactNode } from "react";
import { observer } from "mobx-react-lite";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  TooltipPortal,
} from "@/components/ui/tooltip";
interface SvgIconProps {
  prefix?: string;
  tip?: string | ReactNode;
  name: string;
  color?: string;
  size?: number | string;
  className?: string;
  onClick?: (e) => void;
}

const SvgIcon = observer((props: SvgIconProps) => {
  const {
    prefix = "icon",
    name,
    color = "#000000",
    size = 16,
    className = "",
    tip = "",
    onClick = (e: any) => {
      e.preventDefault();
    },
  } = props;
  const symbolId = useMemo(() => `#${prefix}-${name}`, [prefix, name]);
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger className={className} type="button">
          <svg
            aria-hidden="true"
            className={className}
            width={size}
            height={size}
            fill={color}
            onClick={onClick}
          >
            <use href={symbolId} fill={color} />
          </svg>
        </TooltipTrigger>
        <TooltipPortal>
          {tip && <TooltipContent>{tip}</TooltipContent>}
        </TooltipPortal>
      </Tooltip>
    </TooltipProvider>
  );
});
export default SvgIcon;
