import { types, flow, SnapshotOut } from "mobx-state-tree";
import { api } from "@/api";

const AssistantItem = types.model("AssistantItem", {
  name: types.string,
  description: types.maybeNull(types.string),
  instructions: types.maybeNull(types.string),
  model: types.string,
  created_at: types.number,
  tools: types.array(types.model({ type: types.string })),
  metadata: types.frozen(),
  id: types.optional(types.string, ""),
  file_ids: types.maybeNull(types.optional(types.array(types.string), [])),
});

export type IAssistantItem = SnapshotOut<typeof AssistantItem>;

const _Assistant = types
  .model("Assistant", {
    currentAssistantId: types.maybeNull(types.string),
    list: types.array(AssistantItem),
    models: types.array(types.string),
    clone: types.boolean,
    edit: types.boolean,
    newFile: types.number,
  })
  .views((self) => ({
    get assistantMap() {
      return self.list.reduce((acc, a) => {
        acc[a.id] = a;
        return acc;
      }, {} as Record<string, IAssistantItem>);
    },
  }))
  .views((self) => ({
    get currentAssistant() {
      return self.currentAssistantId
        ? self.assistantMap[self.currentAssistantId]
        : null;
    },
  }))
  .actions((self) => ({
    setCurrentAssistantId(id: string) {
      self.currentAssistantId = id;
    },
    setClone(val: boolean) {
      self.clone = val;
    },
    setEdit(val: boolean) {
      self.edit = val;
    },
    setNewFile() {
      self.newFile += 1;
    },
  }));

export const Assistant = _Assistant.actions((self) => {
  const setList = (list: IAssistantItem[]) => {
    self.list.replace(list as any);
  };
  const loadAsistants = flow(function* () {
    const asis: ResolvePromiseReturnType<typeof api.getAssistants> =
      yield api.getAssistants();

    asis.data.forEach((a) => {
      if (!a.name) {
        a.name = a.id;
      }
      if (!a.metadata) {
        a.metadata = {};
      }
    });
    setList(asis.data);
    return asis.data;
  });

  /** 加载模型列表*/
  const loadModels = flow(function* () {
    const models: ResolvePromiseReturnType<typeof api.getModelList> =
      yield api.getModelList();
    const list = models.data.map((item) => item.id);
    self.models.replace(list);
  });

  const searchAll = flow(function* (data: {
    search: string;
    assistant_id: string;
    run_id: string;
    thread_id: string;
    message_id: string;
    conversation_id: string;
    communication_id: string;
  }) {
    return yield api.search_all(data);
  });

  const addAgentium = flow(function* (data: {
    version;
    name;
    user_id;
    assistant_id;
    meta_data;
  }) {
    return yield api.addAgentium(data);
  });

  const updateAgentiumName = flow(function* (data: { id; name }) {
    return yield api.updateAgentiumName(data);
  });

  const getAgentium = flow(function* ({ page_num, page_size, assistant_id }) {
    return yield api.getAgentium({ page_num, page_size, assistant_id });
  });

  const addMyqameta = flow(function* (data: {
    version;
    name;
    user_id;
    assistant_id;
    meta_data;
  }) {
    return yield api.addMyqameta(data);
  });

  const updateMyqametaName = flow(function* (data: { id; name }) {
    return yield api.updateMyqametaName(data);
  });

  const getMyqameta = flow(function* ({ page_num, page_size, assistant_id }) {
    return yield api.getMyqameta({ page_num, page_size, assistant_id });
  });

  const getTarget = flow(function* ({ user_id, assistant_id, date_str }) {
    return yield api.getTarget({ user_id, assistant_id, date_str });
  });

  const getCustomerData = flow(function* (data:{ date_str, assistant_id }) {
    return yield api.getCustomerData(data);
  });
  const getAgentPackage = flow(function* (data:{ plat_form, assistant_id,version }) {
    return yield api.getAgentPackage(data);
  });

  const addIntention = flow(function* (data: {
    version;
    name;
    user_id;
    assistant_id;
    meta_data;
  }) {
    return yield api.addIntention(data);
  });

  const updateIntentionName = flow(function* (data: { id; name }) {
    return yield api.updateIntentionName(data);
  });

  const getIntention = flow(function* ({ page_num, page_size, assistant_id }) {
    return yield api.getIntention({ page_num, page_size, assistant_id });
  });
  
  

  return {
    setList: setList,
    create: flow(function* (body: IAssistantItem) {
      const data = yield api.createAssistant(body);
      return data;
    }),
    update: flow(function* (body: Partial<IAssistantItem>) {
      yield api.updateAssistant(body);
    }),
    delete: flow(function* (id: string) {
      yield api.deleteAssistant(id);
      loadAsistants();
    }),
    load: loadAsistants,
    loadModels,
    searchAll,
    addAgentium,
    updateAgentiumName,
    getAgentium,
    addMyqameta,
    updateMyqametaName,
    getMyqameta,
    getTarget,
    getCustomerData,
    getAgentPackage,
    addIntention,
    updateIntentionName,
    getIntention,
  };
});
